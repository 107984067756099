import React from "react";
import Layout from "../../../other/layout";
import Professionnels from "../../../components/AC-StaticPages/VotreEntreprise/Subpages/Professionnels";
import SEO from "../../../other/seo";

export default function professionnels() {
  return (
    <Layout>
      <SEO title="Professionnels" />
      <Professionnels />
    </Layout>
  );
}
